@import "variables";
@import "~bootstrap/scss/bootstrap";

html {
  scroll-behavior: smooth;
}

body {
  color: #fff;
}

.navbar-collapse {
  flex-grow: 0;
}

.btn-primary {
  color: #fff;
}

//#home {
//  padding-top: 100px;
//}

.nav-link {
  color: rgba(255, 255, 255, 1) !important;
  transition: color 0.3s !important;

  &:hover {
    color: rgba(255, 255, 255, .6) !important;
  }
}
